import { OfferAction } from '@constants/CDataActions';
import { TOffer } from '@typescript/models/Offer.model';
import { OfferStatus } from '@constants/COffer';
import { Box } from '@mui/material';
import {
  ButtonGradientDark,
  BuyGradientDark,
  DeleteButton,
} from '@styles/shared/SButtons';
import { gridRow } from '@styles/shared/SDataGrid';
import { GridColumns } from '@mui/x-data-grid';
import {
  OfferStatusCell,
  askingPriceCell,
  currentTokenPriceCell,
  expireInCell,
  offerPriceCell,
  projectCell,
  tokenAmountCell,
  totalCostCell,
} from '@components/tables-columns/TableCell';
import { CurrentUser } from '@typescript/models/User.model';

export const baseOffersColumns: GridColumns<TOffer> = [
  {
    field: 'id',
    headerName: 'ID',
    ...gridRow,
    hide: true,
    valueGetter: ({ row }) => row.id,
  },
  {
    field: 'item',
    headerName: 'Item',
    type: 'string',
    sortingOrder: ['asc', 'desc'],
    ...{ ...gridRow, minWidth: 155 },
    valueGetter: ({ row }) => projectCell(row.listing.project).valueGetter,
    renderCell: ({ row }) => projectCell(row.listing.project).renderCell,
  },
  {
    field: 'Current Token Price',
    headerName: 'Current Token Price',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) =>
      currentTokenPriceCell(row.listing.project.currentPrice).valueGetter,
    renderCell: ({ row }) =>
      currentTokenPriceCell(row.listing.project.currentPrice).renderCell,
  },
  {
    field: 'Listing Asking Price',
    headerName: 'Listing Asking Price',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => askingPriceCell(row.listing).valueGetter,
    renderCell: ({ row }) => askingPriceCell(row.listing).renderCell,
  },
  {
    field: 'Listing Token Amount',
    headerName: 'Listing Token Amount',
    type: 'number',
    sortingOrder: ['asc', 'desc'],
    ...gridRow,
    valueGetter: ({ row }) => tokenAmountCell(row.listing.amount).valueGetter,
    renderCell: ({ row }) => tokenAmountCell(row.listing.amount).renderCell,
  },
  {
    field: 'Listing Total Cost',
    headerName: 'Listing Total Cost',
    type: 'number',
    sortingOrder: ['asc', 'desc'],
    ...gridRow,
    valueGetter: ({ row }) => totalCostCell(row.listing.price).valueGetter,
    renderCell: ({ row }) => totalCostCell(row.listing.price).renderCell,
  },
  {
    field: 'Offer Price',
    headerName: 'Offer Price',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => offerPriceCell(row).valueGetter,
    renderCell: ({ row }) => offerPriceCell(row).renderCell,
  },
  {
    field: 'Total Offer Cost',
    headerName: 'Total Offer Cost',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => totalCostCell(row.offeredPrice).valueGetter,
    renderCell: ({ row }) => totalCostCell(row.offeredPrice).renderCell,
  },
  {
    field: 'expireIn',
    headerName: 'Expire In',
    type: 'date',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => expireInCell(row.listing).valueGetter,
    renderCell: ({ row }) => expireInCell(row.listing).renderCell,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'date',
    ...gridRow,
    minWidth: 155,
    sortingOrder: ['asc', 'desc'],
    renderCell: ({ row }) => <OfferStatusCell offer={row} />,
  },
];

type HandleOfferAction = (newOffer: TOffer, newAction: OfferAction) => void;

export const getOfferActions = (
  offer: TOffer,
  changeAction: HandleOfferAction,
  insufficientFunds: (amount: number) => boolean,
  user?: CurrentUser,
) => {
  if (!user?.id) return null;

  const receivedOffer = offer.listing.user.id === user?.id;

  const noFunds = insufficientFunds(offer.offeredPrice);

  const sendedOffer = offer.offerer.id === user?.id;

  if (!receivedOffer && !sendedOffer) {
    throw Error(
      `offer ${offer} is not a sended offer in displayAction and not a received offer`,
    );
  }

  if (offer.listing.transactionInProgress) {
    return null;
  }

  if (receivedOffer) {
    if (offer.status === OfferStatus.PENDING) {
      return (
        <Box display="flex" gap={1} width="100%">
          <ButtonGradientDark
            fullWidth
            disableRipple
            onClick={() => changeAction(offer, OfferAction.ACCEPT_OFFER)}
          >
            {OfferAction.ACCEPT_OFFER}
          </ButtonGradientDark>
          <DeleteButton
            fullWidth
            variant="outlined"
            onClick={() => changeAction(offer, OfferAction.REJECT_OFFER)}
          >
            {OfferAction.REJECT_OFFER}
          </DeleteButton>
        </Box>
      );
    }

    if (offer.status === OfferStatus.ACCEPTED) {
      return (
        <Box display="flex" gap={1} width="100%">
          <DeleteButton
            fullWidth
            variant="outlined"
            onClick={() => changeAction(offer, OfferAction.REJECT_OFFER)}
          >
            {OfferAction.REJECT_OFFER}
          </DeleteButton>
        </Box>
      );
    }

    if (offer.status === OfferStatus.BLOCKED) {
      return null;
    }

    if (offer.status === OfferStatus.PERFORMED) {
      return null;
    }
  }

  if (sendedOffer) {
    if (noFunds) {
      return null;
    }
    if (offer.status === OfferStatus.PENDING) {
      return (
        <Box display="flex" gap={1} width="100%">
          <ButtonGradientDark
            fullWidth
            disableRipple
            onClick={() => changeAction(offer, OfferAction.EDIT)}
          >
            {OfferAction.EDIT}
          </ButtonGradientDark>
          <DeleteButton
            fullWidth
            variant="outlined"
            onClick={() => changeAction(offer, OfferAction.DELETE)}
          >
            {OfferAction.DELETE}
          </DeleteButton>
        </Box>
      );
    }
    if (offer.status === OfferStatus.ACCEPTED) {
      return (
        <Box display="flex" gap={1} width="100%">
          <BuyGradientDark
            onClick={() =>
              changeAction(offer, OfferAction.MAKE_OFFER_TRANSACTION)
            }
          >
            {OfferAction.MAKE_OFFER_TRANSACTION}
          </BuyGradientDark>
          <DeleteButton
            fullWidth
            variant="outlined"
            onClick={() => changeAction(offer, OfferAction.DELETE)}
          >
            {OfferAction.DELETE}
          </DeleteButton>
        </Box>
      );
    }
    if (offer.status === OfferStatus.BLOCKED) {
      return null;
    }
    if (offer.status === OfferStatus.PERFORMED) {
      return null;
    }
  }

  throw Error(`offer ${offer.id} has no action in displayAction`);
};

export const getOffersColumnsWithActions = (
  changeAction: (newOffer: TOffer, newAction: OfferAction) => void,
  insufficientFunds: (amount: number) => boolean,
  user?: CurrentUser,
): GridColumns<TOffer> => {
  return [
    ...baseOffersColumns,
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'date',
      ...{ ...gridRow, minWidth: 330 },
      sortingOrder: ['asc', 'desc'],
      renderCell: ({ row }) =>
        getOfferActions(row, changeAction, insufficientFunds, user),
    },
  ];
};
