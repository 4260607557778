import {
  currentTokenPriceCell,
  performanceCell,
  projectCell,
  tokenAmountCell,
  tokenValueCell,
} from '@components/tables-columns/TableCell';
import { ProjectStatus } from '@constants/CProject';
import { GridColumns } from '@mui/x-data-grid';
import { SellGradientDark } from '@styles/shared/SButtons';
import { gridRow } from '@styles/shared/SDataGrid';
import { TAllocation } from '@typescript/models/Allocation.model';

type HandleAllocationFn = (allocation: TAllocation) => void;

export const displayAllocationActions = (
  allocation: TAllocation,
  handleAllocationActions: HandleAllocationFn,
) => {
  switch (true) {
    case allocation.project.status === ProjectStatus.LIVE ||
      allocation.project.status === ProjectStatus.LIVE_PRIVATE ||
      allocation.project.status === ProjectStatus.REFUNDED:
      return null;
    default:
      return (
        <SellGradientDark
          disabled={allocation.lockedTokens <= 0}
          sx={{ marginBottom: '0' }}
          onClick={() => handleAllocationActions(allocation)}
        >
          Sell
        </SellGradientDark>
      );
  }
};

const allocationsColumns: GridColumns<TAllocation> = [
  {
    field: 'Project',
    headerName: 'Project',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    minWidth: 140,
    type: 'string',
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => projectCell(row.project).valueGetter,
    renderCell: ({ row }) => projectCell(row.project).renderCell,
  },
  {
    field: 'Balance',
    headerName: 'Balance',
    type: 'number',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => tokenAmountCell(row.lockedTokens).valueGetter,
    renderCell: ({ row }) => tokenAmountCell(row.lockedTokens).renderCell,
  },
  {
    field: 'Current Token Price',
    headerName: 'Current Token Price',
    type: 'number',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) =>
      currentTokenPriceCell(row.project.currentPrice).valueGetter,
    renderCell: ({ row }) =>
      currentTokenPriceCell(row.project.currentPrice).renderCell,
  },
  {
    field: 'Value Locked',
    headerName: 'Value Locked',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => tokenValueCell(row.lockedValue).valueGetter,
    renderCell: ({ row }) => tokenValueCell(row.lockedValue).renderCell,
  },
  {
    field: 'Value Unlocked',
    headerName: 'Value Unlocked',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => tokenValueCell(row.unlockedValue).valueGetter,
    renderCell: ({ row }) => tokenValueCell(row.unlockedValue).renderCell,
  },
  {
    field: 'Performance',
    headerName: 'Performance',
    type: 'number',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) =>
      performanceCell(row.project.performance).valueGetter,
    renderCell: ({ row }) =>
      performanceCell(row.project.performance).renderCell,
  },
];

export const getAllocationsColumnsWithActions = (
  handleSellNowBtn: HandleAllocationFn,
): GridColumns<TAllocation> => {
  return [
    ...allocationsColumns,
    {
      field: 'action',
      headerName: '',
      ...gridRow,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => displayAllocationActions(row, handleSellNowBtn),
    },
  ];
};
