import {
  confirmationCell,
  projectCell,
  TimeCell,
  tokenAmountCell,
  totalCostCell,
  tradeTypeCell,
  WalletCell,
} from '@components/tables-columns/TableCell';
import { GridColumns } from '@mui/x-data-grid';
import { gridRow } from '@styles/shared/SDataGrid';
import { TTransaction } from '@typescript/models/TradeTransaction.model';

export const baseTradeTxColumns: GridColumns<TTransaction> = [
  {
    field: 'id',
    headerName: 'ID',
    ...gridRow,
    hide: true,
    valueGetter: ({ row }) => row.id,
  },
  {
    field: 'Event',
    headerName: 'Event',
    ...gridRow,
    maxWidth: 80,
    type: 'string',
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => tradeTypeCell(row.tradeType).valueGetter,
    renderCell: ({ row }) => tradeTypeCell(row.tradeType).renderCell,
  },
  {
    field: 'item',
    headerName: 'Item',
    type: 'string',
    sortingOrder: ['asc', 'desc'],
    ...{ ...gridRow, minWidth: 155 },
    valueGetter: ({ row }) => projectCell(row.project).valueGetter,
    renderCell: ({ row }) => projectCell(row.project).renderCell,
  },
  {
    field: 'Total Cost',
    headerName: 'Total Cost',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => totalCostCell(row.value).valueGetter,
    renderCell: ({ row }) => totalCostCell(row.value).renderCell,
  },
  {
    field: 'Token Amount',
    headerName: 'Token Amount',
    type: 'number',
    sortingOrder: ['asc', 'desc'],
    ...gridRow,
    valueGetter: ({ row }) => tokenAmountCell(row.tokenAmount).valueGetter,
    renderCell: ({ row }) => tokenAmountCell(row.tokenAmount).renderCell,
  },
  {
    field: 'from',
    headerName: 'From',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    renderCell: ({ row }) => <WalletCell wallet={row.from.ethAddress} />,
  },
  {
    field: 'to',
    headerName: 'To',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    renderCell: ({ row }) => <WalletCell wallet={row.to.ethAddress} />,
  },
  {
    field: 'time',
    headerName: 'Time',
    type: 'date',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    renderCell: ({ row }) => (
      <TimeCell hash={row.hash} createdAt={row.createdAt} />
    ),
  },
  {
    field: 'confirmed',
    headerName: 'Confirmed',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => confirmationCell(row.confirmed).valueGetter,
    renderCell: ({ row }) => confirmationCell(row.confirmed).renderCell,
  },
];
