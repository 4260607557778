import { GridColumns } from '@mui/x-data-grid';
import { gridRow } from '@styles/shared/SDataGrid';
import { TTransactionInvestment } from '@typescript/models/InvestmentTransaction.model';
import {
  TimeCell,
  WalletCell,
  confirmationCell,
  feeCell,
  projectCell,
  tokenAmountCell,
  totalCostCell,
} from './TableCell';

export const baseInvestmentTxColumns: GridColumns<TTransactionInvestment> = [
  {
    field: 'id',
    headerName: 'ID',
    ...gridRow,
    hide: true,
    valueGetter: ({ row }) => row.id,
  },
  {
    field: 'item',
    headerName: 'Item',
    type: 'string',
    sortingOrder: ['asc', 'desc'],
    ...{ ...gridRow, minWidth: 155 },
    valueGetter: ({ row }) => projectCell(row.project).valueGetter,
    renderCell: ({ row }) => projectCell(row.project).renderCell,
  },
  {
    field: 'Investment Amount',
    headerName: 'Investment Amount',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => totalCostCell(row.investmentAmount).valueGetter,
    renderCell: ({ row }) => totalCostCell(row.investmentAmount).renderCell,
  },
  {
    field: 'Token Amount',
    headerName: 'Token Amount',
    type: 'number',
    sortingOrder: ['asc', 'desc'],
    ...gridRow,
    valueGetter: ({ row }) => tokenAmountCell(row.totalTokens).valueGetter,
    renderCell: ({ row }) => tokenAmountCell(row.totalTokens).renderCell,
  },
  {
    field: 'Fee',
    headerName: 'Fee',
    type: 'number',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => feeCell(row.feeInPercentage).valueGetter,
    renderCell: ({ row }) => feeCell(row.feeInPercentage).renderCell,
  },
  {
    field: 'from',
    headerName: 'From',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    renderCell: ({ row }) => <WalletCell wallet={row.from.ethAddress} />,
  },
  {
    field: 'to',
    headerName: 'To',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    renderCell: ({ row }) => <WalletCell wallet={row.to} />,
  },
  {
    field: 'time',
    headerName: 'Time',
    type: 'date',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    renderCell: ({ row }) => (
      <TimeCell hash={row.transactionHash} createdAt={row.createdAt} />
    ),
  },
  {
    field: 'confirmed',
    headerName: 'Confirmed',
    type: 'string',
    ...gridRow,
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => confirmationCell(row.confirmed).valueGetter,
    renderCell: ({ row }) => confirmationCell(row.confirmed).renderCell,
  },
];
