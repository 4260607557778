/* eslint-disable import/no-unused-modules */
import { Box } from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import { toRem } from '@utils/functions';
import { Link } from 'react-router-dom';
import { ListingAction, OfferAction } from '@constants/CDataActions';
import { OfferStatus } from '@constants/COffer';
import { ListingType } from '@constants/CListings';
import { isPast } from 'date-fns';
import {
  DeleteButton,
  ButtonGradientDark,
  SellGradientDark,
  BuyGradientDark,
} from '@styles/shared/SButtons';
import { TOffer } from '@typescript/models/Offer.model';
import { gridRow } from '@styles/shared/SDataGrid';
import { GridColumns } from '@mui/x-data-grid';
import { ListingTradeStatus } from '@constants/CDataStatus';
import { CurrentUser } from '@typescript/models/User.model';
import useUser from '@hooks/useUser';
import {
  SocialCell,
  ListingStatusCell,
  askingPriceCell,
  currentTokenPriceCell,
  expireInCell,
  negotiableCell,
  offeredPriceCell,
  projectCell,
  tokenAmountCell,
  totalCostCell,
  totalOfferCostCell,
  listingTypeCell,
} from './TableCell';

type HandleListingFn = (
  listing: TListing,
  listingAction: ListingAction,
) => void;

type HandleOfferFn = (
  listing: TListing,
  offerAction: OfferAction,
  offer: TOffer,
) => void;

interface LoginProps {
  action?: () => void;
}

export const baseListingsColumns: GridColumns<TListing> = [
  {
    field: 'id',
    headerName: 'ID',
    filterable: false,
    ...gridRow,
    hide: true,
    valueGetter: ({ row: listing }) => listing.id,
  },
  {
    field: 'item',
    headerName: 'Item',
    ...{ ...gridRow, minWidth: 120, maxWidth: 215 },
    type: 'string',
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => projectCell(row.project).valueGetter,
    renderCell: ({ row }) => projectCell(row.project).renderCell,
  },
  {
    field: 'currentTokenPrice',
    headerName: 'Current Token Price',
    ...{ ...gridRow, minWidth: 100, maxWidth: 150 },
    type: 'number',
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) =>
      currentTokenPriceCell(row.project.currentPrice).valueGetter,
    renderCell: ({ row }) =>
      currentTokenPriceCell(row.project.currentPrice).renderCell,
  },
  {
    field: 'AskingPrice',
    headerName: 'Asking Price ',
    type: 'number',
    ...{ ...gridRow, minWidth: 100, maxWidth: 125 },
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => askingPriceCell(row).valueGetter,
    renderCell: ({ row }) => askingPriceCell(row).renderCell,
  },
  {
    field: 'TokenAmount',
    headerName: 'Token Amount',
    type: 'number',
    sortingOrder: ['asc', 'desc'],
    ...{ ...gridRow, minWidth: 130, maxWidth: 130 },
    valueGetter: ({ row }) => tokenAmountCell(row.amount).valueGetter,
    renderCell: ({ row }) => tokenAmountCell(row.amount).renderCell,
  },
  {
    field: 'TotalCost',
    headerName: 'Total Cost',
    type: 'number',
    ...{ ...gridRow, minWidth: 60, maxWidth: 100 },
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => totalCostCell(row.price).valueGetter,
    renderCell: ({ row }) => totalCostCell(row.price).renderCell,
  },
  {
    field: 'Offer Price',
    headerName: 'Offered Price',
    type: 'string',
    ...{ ...gridRow, minWidth: 90, maxWidth: 100 },
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => offeredPriceCell(row).valueGetter,
    renderCell: ({ row }) => offeredPriceCell(row).renderCell,
  },
  {
    field: 'Total Offer Cost',
    headerName: 'Total Offer Cost',
    type: 'string',
    ...{ ...gridRow, minWidth: 130, maxWidth: 150 },
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => totalOfferCostCell(row).valueGetter,
    renderCell: ({ row }) => totalOfferCostCell(row).renderCell,
  },
  {
    field: 'negotiable',
    headerName: 'Negotiable',
    filterable: false,
    type: 'string',
    ...{ ...gridRow, minWidth: 90, maxWidth: 100 },
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => negotiableCell(row).valueGetter,
    renderCell: ({ row }) => negotiableCell(row).renderCell,
  },
  {
    field: 'expireIn',
    headerName: 'Expire In',
    type: 'date',
    ...{ ...gridRow, minWidth: 70, maxWidth: 90 },
    sortingOrder: ['asc', 'desc'],
    valueGetter: ({ row }) => expireInCell(row).valueGetter,
    renderCell: ({ row }) => expireInCell(row).renderCell,
  },
  {
    field: 'Status',
    filterable: false,
    headerName: 'Status',
    ...{ ...gridRow, minWidth: 100, maxWidth: 100 },
    type: 'string',
    sortingOrder: ['asc', 'desc'],
    valueGetter: () => null,
    renderCell: ({ row }) => <ListingStatusCell listing={row} />,
  },
  {
    field: 'Social',
    filterable: false,
    headerName: 'Social',
    ...{ ...gridRow, minWidth: 100, maxWidth: 100 },
    sortingOrder: ['asc', 'desc'],
    renderCell: ({ row }) => <SocialCell listing={row} />,
  },
];

export function getTradeListingActions(
  listing: TListing,
  handleListingActions: HandleListingFn,
  handleActionOrConnect: (action?: LoginProps['action']) => void,
  user?: CurrentUser,
) {
  const {
    user: userListing,
    transaction,
    acceptedBy,
    type,
    transactionInProgress,
    negotiable,
    tradeStatus,
    ownedListingOffer,
    listingHasOffers,
  } = listing;

  const ownListing = user?.id === userListing.id;

  const listingAccepted = acceptedBy && !transaction?.id;

  const isWTS = type === ListingType.SELL;

  const isWTB = type === ListingType.BUY;

  if (ListingTradeStatus.SUSPENDED === tradeStatus) {
    return null;
  }
  if (transactionInProgress) {
    return null;
  }

  const handleBuyWTS = () => {
    handleListingActions(listing, ListingAction.BUY_WTS);
  };
  const handleMakeOffer = () => {
    handleListingActions(listing, ListingAction.MAKE_OFFER);
  };

  const handleBuyWTB = () => {
    handleListingActions(listing, ListingAction.BUY_WTB);
  };
  const handleSellWTB = () => {
    handleListingActions(listing, ListingAction.SELL_WTB);
  };

  if (!ownListing && isWTS && negotiable && !ownedListingOffer) {
    return (
      <>
        <BuyGradientDark onClick={() => handleActionOrConnect(handleBuyWTS)}>
          {ListingAction.BUY_WTS}
        </BuyGradientDark>
        <BuyGradientDark onClick={() => handleActionOrConnect(handleMakeOffer)}>
          {ListingAction.MAKE_OFFER}
        </BuyGradientDark>
      </>
    );
  }

  if (ownedListingOffer && ownedListingOffer.status === OfferStatus.ACCEPTED) {
    return (
      <Link
        to={`/offers/${!isPast(new Date(ownedListingOffer.expireAt)) ? 0 : 1}`}
      >
        <ButtonGradientDark fullWidth sx={{ gridColumn: '2' }}>
          {ListingAction.VIEW_OFFER}
        </ButtonGradientDark>
      </Link>
    );
  }

  if (ownedListingOffer && !ownListing) {
    return (
      <Link to="/offers">
        <ButtonGradientDark fullWidth sx={{ gridColumn: '2' }}>
          {ListingAction.VIEW_OFFER}
        </ButtonGradientDark>
      </Link>
    );
  }

  if (ownListing && !ownedListingOffer && listingHasOffers) {
    return (
      <Link to="/offers">
        <ButtonGradientDark fullWidth sx={{ gridColumn: '2' }}>
          {ListingAction.VIEW_OFFER}
        </ButtonGradientDark>
      </Link>
    );
  }

  if (ownListing && isWTB && listingAccepted) {
    return (
      <BuyGradientDark onClick={() => handleActionOrConnect(handleBuyWTB)}>
        {ListingAction.BUY_WTB}
      </BuyGradientDark>
    );
  }

  if (!ownListing && isWTS && !negotiable) {
    return (
      <BuyGradientDark onClick={() => handleActionOrConnect(handleBuyWTS)}>
        {ListingAction.BUY_WTS}
      </BuyGradientDark>
    );
  }

  if (!ownListing) {
    if (
      !listingAccepted &&
      ListingTradeStatus.INSUFFICIENT_TOKENS !== tradeStatus
    ) {
      if (ListingTradeStatus.EXPIRED !== tradeStatus) {
        return (
          <SellGradientDark
            onClick={() => handleActionOrConnect(handleSellWTB)}
          >
            {ListingAction.SELL_WTB}
          </SellGradientDark>
        );
      }
    }
  }

  return null;
}

export function getAllListingsActions(
  listing: TListing,
  handleListingActions: HandleListingFn,
  handleOfferActions: HandleOfferFn,
  user?: CurrentUser,
) {
  const {
    transaction,
    acceptedBy,
    type,
    user: listingUser,
    transactionInProgress,
    negotiable,
    tradeStatus,
    ownedListingOffer,
    listingHasOffers,
  } = listing;

  const ownListing = user?.id === listingUser.id;

  const listingAccepted = acceptedBy && !transaction?.id;

  const isWTS = type === ListingType.SELL;

  const isWTB = type === ListingType.BUY;

  if (ListingTradeStatus.SUSPENDED === tradeStatus) {
    return null;
  }
  if (transactionInProgress) {
    return null;
  }

  if (
    !ownListing &&
    isWTS &&
    negotiable &&
    tradeStatus === ListingTradeStatus.AVAILABLE
  ) {
    return (
      <Box display="flex" gap={1} width="100%">
        <BuyGradientDark
          onClick={() => handleListingActions(listing, ListingAction.BUY_WTS)}
        >
          {ListingAction.BUY_WTS}
        </BuyGradientDark>
        <BuyGradientDark
          onClick={() =>
            handleListingActions(listing, ListingAction.MAKE_OFFER)
          }
        >
          {ListingAction.MAKE_OFFER}
        </BuyGradientDark>
      </Box>
    );
  }

  if (ownedListingOffer && ownedListingOffer.status === OfferStatus.ACCEPTED) {
    return (
      <Box display="flex" gap={1} width="100%">
        <BuyGradientDark
          onClick={() =>
            handleOfferActions(
              listing,
              OfferAction.MAKE_OFFER_TRANSACTION,
              ownedListingOffer,
            )
          }
        >
          {OfferAction.MAKE_OFFER_TRANSACTION}
        </BuyGradientDark>
        <DeleteButton
          fullWidth
          variant="outlined"
          onClick={() =>
            handleOfferActions(listing, OfferAction.DELETE, ownedListingOffer)
          }
        >
          {OfferAction.DELETE}
        </DeleteButton>
      </Box>
    );
  }

  if (ownedListingOffer && !ownListing) {
    return (
      <Box display="flex" gap={1} width="100%">
        <Link
          to={`/offers/${
            !isPast(new Date(ownedListingOffer.expireAt)) ? 0 : 1
          }`}
        >
          <ButtonGradientDark fullWidth disableRipple>
            {ListingAction.VIEW_OFFER}
          </ButtonGradientDark>
        </Link>
        <DeleteButton
          fullWidth
          variant="outlined"
          onClick={() =>
            handleOfferActions(listing, OfferAction.DELETE, ownedListingOffer)
          }
        >
          {OfferAction.DELETE}
        </DeleteButton>
      </Box>
    );
  }

  if (ownListing && listingHasOffers) {
    return (
      <Box display="flex" gap={1} width="100%">
        <Link to="/offers" style={{ width: '100%' }}>
          <ButtonGradientDark fullWidth disableRipple>
            {ListingAction.VIEW_OFFER}
          </ButtonGradientDark>
        </Link>
        <DeleteButton
          fullWidth
          variant="outlined"
          onClick={() => handleListingActions(listing, ListingAction.DELETE)}
        >
          {ListingAction.DELETE}
        </DeleteButton>
      </Box>
    );
  }

  if (ownListing && isWTB && listingAccepted) {
    return (
      <Box display="flex" gap={1} width="100%">
        <BuyGradientDark
          onClick={() => handleListingActions(listing, ListingAction.BUY_WTB)}
        >
          {ListingAction.BUY_WTB}
        </BuyGradientDark>
        <DeleteButton
          fullWidth
          variant="outlined"
          onClick={() => handleListingActions(listing, ListingAction.DELETE)}
        >
          {ListingAction.DELETE}
        </DeleteButton>
      </Box>
    );
  }

  if (!ownListing && isWTS && !negotiable) {
    return (
      <SellGradientDark
        onClick={() => handleListingActions(listing, ListingAction.BUY_WTS)}
      >
        {ListingAction.BUY_WTS}
      </SellGradientDark>
    );
  }

  if (!ownListing) {
    if (
      !listingAccepted &&
      ListingTradeStatus.INSUFFICIENT_TOKENS !== tradeStatus
    ) {
      return (
        <BuyGradientDark
          onClick={() => handleListingActions(listing, ListingAction.SELL_WTB)}
        >
          {ListingAction.SELL_WTB}
        </BuyGradientDark>
      );
    }
  }

  if (ownListing) {
    return (
      <Box display="flex" gap={1} width="100%">
        <ButtonGradientDark
          disableRipple
          fullWidth
          onClick={() => handleListingActions(listing, ListingAction.EDIT)}
          disabled={!!acceptedBy || Boolean(ownedListingOffer)}
        >
          {ListingAction.EDIT}
        </ButtonGradientDark>
        <DeleteButton
          fullWidth
          variant="outlined"
          onClick={() => handleListingActions(listing, ListingAction.DELETE)}
        >
          {ListingAction.DELETE}
        </DeleteButton>
      </Box>
    );
  }
  return null;
}

export const getListingsColumnsWithTradeActions = (
  handleAction: HandleListingFn,
  handleActionOrConnect: (action?: LoginProps['action']) => void,
  user?: CurrentUser,
): GridColumns<TListing> => {
  return [
    ...baseListingsColumns,
    {
      field: 'Actions',
      filterable: false,
      headerName: '',
      sortable: false,
      ...gridRow,
      minWidth: 250,
      renderCell: ({ row: listing }) => (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: toRem(3),
            width: '100%',
          }}
        >
          {getTradeListingActions(
            listing,
            handleAction,
            handleActionOrConnect,
            user,
          )}
        </Box>
      ),
    },
  ];
};

export const getListingsColumnsWithAllActions = (
  handleListingActions: HandleListingFn,
  handleOfferActions: HandleOfferFn,
  user?: CurrentUser,
): GridColumns<TListing> => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      ...gridRow,
      hide: true,
      valueGetter: ({ row: listing }) => listing.id,
    },
    {
      field: 'item',
      headerName: 'Item',
      ...{ ...gridRow, minWidth: 120, maxWidth: 215 },
      type: 'string',
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => projectCell(row.project).valueGetter,
      renderCell: ({ row }) => projectCell(row.project).renderCell,
    },
    {
      field: 'Type',
      filterable: false,
      headerName: 'Listing Type',
      ...{ ...gridRow, minWidth: 70, maxWidth: 70 },
      sortingOrder: ['asc', 'desc'],
      renderCell: ({ row }) => listingTypeCell(row).renderCell,
    },
    {
      field: 'currentTokenPrice',
      headerName: 'Current Token Price',
      ...{ ...gridRow, minWidth: 100, maxWidth: 150 },
      type: 'number',
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) =>
        currentTokenPriceCell(row.project.currentPrice).valueGetter,
      renderCell: ({ row }) =>
        currentTokenPriceCell(row.project.currentPrice).renderCell,
    },
    {
      field: 'AskingPrice',
      headerName: 'Asking Price ',
      type: 'number',
      ...{ ...gridRow, minWidth: 100, maxWidth: 125 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => askingPriceCell(row).valueGetter,
      renderCell: ({ row }) => askingPriceCell(row).renderCell,
    },
    {
      field: 'TokenAmount',
      headerName: 'Token Amount',
      type: 'number',
      sortingOrder: ['asc', 'desc'],
      ...{ ...gridRow, minWidth: 130, maxWidth: 130 },
      valueGetter: ({ row }) => tokenAmountCell(row.amount).valueGetter,
      renderCell: ({ row }) => tokenAmountCell(row.amount).renderCell,
    },
    {
      field: 'TotalCost',
      headerName: 'Total Cost',
      type: 'number',
      ...{ ...gridRow, minWidth: 60, maxWidth: 100 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => totalCostCell(row.price).valueGetter,
      renderCell: ({ row }) => totalCostCell(row.price).renderCell,
    },
    {
      field: 'Offer Price',
      headerName: 'Offered Price',
      type: 'string',
      ...{ ...gridRow, minWidth: 90, maxWidth: 100 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => offeredPriceCell(row).valueGetter,
      renderCell: ({ row }) => offeredPriceCell(row).renderCell,
    },
    {
      field: 'Total Offer Cost',
      headerName: 'Total Offer Cost',
      type: 'string',
      ...{ ...gridRow, minWidth: 130, maxWidth: 150 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => totalOfferCostCell(row).valueGetter,
      renderCell: ({ row }) => totalOfferCostCell(row).renderCell,
    },
    {
      field: 'negotiable',
      headerName: 'Negotiable',
      filterable: false,
      type: 'string',
      ...{ ...gridRow, minWidth: 90, maxWidth: 100 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => negotiableCell(row).valueGetter,
      renderCell: ({ row }) => negotiableCell(row).renderCell,
    },
    {
      field: 'expireIn',
      headerName: 'Expire In',
      type: 'date',
      ...{ ...gridRow, minWidth: 70, maxWidth: 90 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => expireInCell(row).valueGetter,
      renderCell: ({ row }) => expireInCell(row).renderCell,
    },
    {
      field: 'Status',
      filterable: false,
      headerName: 'Status',
      ...{ ...gridRow, minWidth: 100, maxWidth: 100 },
      type: 'string',
      sortingOrder: ['asc', 'desc'],
      valueGetter: () => null,
      renderCell: ({ row }) => <ListingStatusCell listing={row} />,
    },
    {
      field: 'Social',
      filterable: false,
      headerName: 'Social',
      ...{ ...gridRow, minWidth: 100, maxWidth: 100 },
      sortingOrder: ['asc', 'desc'],
      renderCell: ({ row }) => <SocialCell listing={row} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'date',
      ...{ ...gridRow, minWidth: 330 },
      sortingOrder: ['asc', 'desc'],
      renderCell: ({ row }) =>
        getAllListingsActions(
          row,
          handleListingActions,
          handleOfferActions,
          user,
        ),
    },
  ];
};

export function GetInactiveListingActions({
  listing,
  handleListingActions,
}: {
  listing: TListing;
  handleListingActions: HandleListingFn;
}) {
  const { currentUser } = useUser();

  if (
    listing.type === ListingType.SELL &&
    listing.user.id === currentUser?.id
  ) {
    return (
      <Box display="flex" gap={1} width="100%">
        <BuyGradientDark
          onClick={() =>
            handleListingActions(
              listing,
              ListingAction.CLAIM_EXPIRED_LISTING_TOKENS,
            )
          }
        >
          {ListingAction.CLAIM_EXPIRED_LISTING_TOKENS}
        </BuyGradientDark>
        <BuyGradientDark
          onClick={() =>
            handleListingActions(
              listing,
              ListingAction.UPDATE_LISTING_EXPIRATION,
            )
          }
        >
          {ListingAction.UPDATE_LISTING_EXPIRATION}
        </BuyGradientDark>
      </Box>
    );
  }

  if (listing.type === ListingType.BUY && listing.user.id === currentUser?.id) {
    return (
      <Box display="flex" gap={1} width="100%">
        <DeleteButton
          fullWidth
          variant="outlined"
          onClick={() => handleListingActions(listing, ListingAction.DELETE)}
        >
          {ListingAction.DELETE}
        </DeleteButton>

        <BuyGradientDark
          onClick={() =>
            handleListingActions(
              listing,
              ListingAction.UPDATE_LISTING_EXPIRATION,
            )
          }
        >
          {ListingAction.UPDATE_LISTING_EXPIRATION}
        </BuyGradientDark>
      </Box>
    );
  }

  if (
    listing.type === ListingType.BUY &&
    listing.acceptedBy?.id === currentUser?.id
  ) {
    return (
      <BuyGradientDark
        onClick={() =>
          handleListingActions(
            listing,
            ListingAction.CLAIM_EXPIRED_LISTING_TOKENS,
          )
        }
      >
        {ListingAction.CLAIM_EXPIRED_LISTING_TOKENS}
      </BuyGradientDark>
    );
  }

  return null;
}

export const inactiveListingActions = (
  handleListingActions: HandleListingFn,
): GridColumns<TListing> => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      ...gridRow,
      hide: true,
      valueGetter: ({ row: listing }) => listing.id,
    },
    {
      field: 'item',
      headerName: 'Item',
      ...{ ...gridRow, minWidth: 120, maxWidth: 215 },
      type: 'string',
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => projectCell(row.project).valueGetter,
      renderCell: ({ row }) => projectCell(row.project).renderCell,
    },
    {
      field: 'Type',
      filterable: false,
      headerName: 'Listing Type',
      ...{ ...gridRow, minWidth: 90, maxWidth: 90 },
      sortingOrder: ['asc', 'desc'],
      renderCell: ({ row }) => listingTypeCell(row).renderCell,
    },
    {
      field: 'currentTokenPrice',
      headerName: 'Current Token Price',
      ...{ ...gridRow, minWidth: 100, maxWidth: 150 },
      type: 'number',
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) =>
        currentTokenPriceCell(row.project.currentPrice).valueGetter,
      renderCell: ({ row }) =>
        currentTokenPriceCell(row.project.currentPrice).renderCell,
    },
    {
      field: 'AskingPrice',
      headerName: 'Asking Price ',
      type: 'number',
      ...{ ...gridRow, minWidth: 100, maxWidth: 125 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => askingPriceCell(row).valueGetter,
      renderCell: ({ row }) => askingPriceCell(row).renderCell,
    },
    {
      field: 'TokenAmount',
      headerName: 'Token Amount',
      type: 'number',
      sortingOrder: ['asc', 'desc'],
      ...{ ...gridRow, minWidth: 130, maxWidth: 130 },
      valueGetter: ({ row }) => tokenAmountCell(row.amount).valueGetter,
      renderCell: ({ row }) => tokenAmountCell(row.amount).renderCell,
    },
    {
      field: 'TotalCost',
      headerName: 'Total Cost',
      type: 'number',
      ...{ ...gridRow, minWidth: 60, maxWidth: 100 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => totalCostCell(row.price).valueGetter,
      renderCell: ({ row }) => totalCostCell(row.price).renderCell,
    },
    {
      field: 'Offer Price',
      headerName: 'Offered Price',
      type: 'string',
      ...{ ...gridRow, minWidth: 90, maxWidth: 100 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => offeredPriceCell(row).valueGetter,
      renderCell: ({ row }) => offeredPriceCell(row).renderCell,
    },
    {
      field: 'Total Offer Cost',
      headerName: 'Total Offer Cost',
      type: 'string',
      ...{ ...gridRow, minWidth: 130, maxWidth: 150 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => totalOfferCostCell(row).valueGetter,
      renderCell: ({ row }) => totalOfferCostCell(row).renderCell,
    },
    {
      field: 'negotiable',
      headerName: 'Negotiable',
      filterable: false,
      type: 'string',
      ...{ ...gridRow, minWidth: 90, maxWidth: 100 },
      sortingOrder: ['asc', 'desc'],
      valueGetter: ({ row }) => negotiableCell(row).valueGetter,
      renderCell: ({ row }) => negotiableCell(row).renderCell,
    },

    {
      field: 'Social',
      filterable: false,
      headerName: 'Social',
      ...{ ...gridRow, minWidth: 100, maxWidth: 100 },
      sortingOrder: ['asc', 'desc'],
      renderCell: ({ row }) => <SocialCell listing={row} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'date',
      ...{ ...gridRow, minWidth: 330 },
      sortingOrder: ['asc', 'desc'],
      renderCell: ({ row }) => (
        <GetInactiveListingActions
          listing={row}
          handleListingActions={handleListingActions}
        />
      ),
    },
  ];
};
